<template>
    <div class="admin-content">
        <h3>사용자 관리</h3>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button class="btn-box" @click="methods.getUserInfo" >조회</button>
                        <button class="btn-box green" @click="{
                            state.dialog.isOpen=true;
                            state.dialog.isNew=true;
                        }">신규</button>
                    </div>
                </div>
                <div>
                    <div class="sch-wrap3">
                        <div>
                            <label>계정 유형</label>
                            <div class="select-box">
                                <select v-model="state.dataTable.selectedUseType">
                                    <option v-for="item in state.selectBox.items" v-bind:value="item.CodeValue" v-bind:key="item" v-text="item.CodeName" />
                                </select>
                            </div>
                        </div>
                        <div>
                            <label>사용자 명</label>
                            <div>
                                <input type="text" class="input-text mr10" v-model.trim="state.dataTable.searchKeyword" @keyup.enter="methods.getUserInfo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- 총 개수 -->
        <div class="total-box">
            <p>Total <strong>{{state.dataTable.rowCount}}</strong></p>
        </div>

        <DataTable
            class="p-datatable-sm" 
            columnResizeMode="fit"
            scrollHeight="497px"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :value="state.dataTable.userItems"
            :paginator="true"
            :rows="15"
            :resizableColumns="true"
            @row-click="eventHandler.dataTable.onRowClick"
            :rowHover="true">

            <template #empty>
                데이터가 없습니다.
            </template>

            <Column field="EmplID" header="사용자 ID" :sortable="true" style="width:200px;" headerClass="tc"></Column>
            <Column field="EmplName" header="사용자명" :sortable="true" style="width:200px;" headerClass="tc"></Column>
            <Column field="UseTypeName" header="계정유형" :sortable="true" style="width:200px;" headerClass="tc"></Column>
            <Column field="Department" header="부서명" :sortable="true" style="width:200px;" headerClass="tc"></Column>
            <Column field="UseYN" header="사용유무" :sortable="true" style="text-align:center; width:100px;" headerClass="tc">
                <template #body="{data}">
                    <div class="chk-box">
                        <input type="checkbox" name="UseYN" v-model="data.UseYN" :binary="true" disabled />
                        <label></label>
                    </div>
                </template>
            </Column>
            <Column field="GrpName" header="권한그룹" :sortable="true" headerClass="tc"></Column>
        </DataTable>

        <Dialog
            :header="state.dialog.header"
            :visible="state.dialog.isOpen"
            :modal="true"
            @show="eventHandler.dialog.show"
            @hide="eventHandler.dialog.hide"
            @close="state.dialog.isOpen=false"
            @update:visible="state.dialog.isOpen = false">

            <div class="dialog-wrap1 col2">
                <div>
                    <label>사용자ID <i class="required">*</i></label>
                    <div class="p-inputgroup">
                        <input class="input-text mr10" ref="emplID" type="text" v-model.trim="state.dataTable.rowDataModel.EmplID" @change="methods.changeEmplID" maxlength="50" :disabled="!state.dialog.isNew" />
                        
                        <br>
                    </div>
                </div>
                <div>
                    <label>사용자명 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="emplName" type="text" v-model.trim="state.dataTable.rowDataModel.EmplName" maxlength="30" />
                    </div>
                </div>
                <div>
                    <label>비밀번호 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="password" type="password" v-model.trim="state.dataTable.rowDataModel.Password" maxlength="255" :disabled="state.dataTable.selectedUseType === 'AD'"/>
                    </div>
                </div>
                <div>
                    <label>계정 유형</label>
                    <div class="select-box">
                       <select v-model="state.dataTable.rowDataModel.UseType">
                            <option v-for="item in state.selectBox.items" v-bind:value="item.CodeValue" v-bind:key="item" v-text="item.CodeName"  />
                       </select>
                    </div>
                </div>
                <div>
                    <label>사용 여부</label>
                    <div class="select-box">
                       <select v-model="state.dataTable.rowDataModel.UseYN">
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                       </select>
                    </div>
                </div>
            </div>
            <br>
            <label><h3>권한 그룹</h3></label>

            <div class="table-wrap">
                <DataTable
                    class="table-box p-datatable-sm"
                    columnResizeMode="fit"
                    :value="state.dataTable.authorityGroup"
                    :resizableColumns="true"
                    :rowHover="true">
                    <template #empty>
                        데이터가 없습니다.
                    </template>
                    <Column field="CheckYN" header="선택" style="text-align:center; width:80px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.CheckYN" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                    <Column field="GrpID" header="권한그룹ID" headerClass="tc"></Column>
                    <Column field="GrpName" header="권한그룹 명" headerClass="tc"></Column>
                </DataTable>
            </div>
            <br>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button class="btn-box green" v-show="state.dialog.isNew" @click="methods.duplicateEmplID" >ID중복체크</button>
                        <button v-show="state.dialog.isNew" class="btn-box gray" @click="methods.userSaveValidation" >저장</button>
                        <button v-show="!state.dialog.isNew" class="btn-box gray" @click="methods.userUpdateValidation" >수정</button>
                        <button v-show="!state.dialog.isNew" :visible="!state.dialog.isNew" class="btn-box red" @click="eventHandler.onDeleteButtonClick" >삭제</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { ref, reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { userManage } from '@/modules/system/PCM_SM0310E.js';
import { common } from '@/modules/common/common.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
export default {
    components: {
        
    },
    setup() {

        // 스토어
        const store = useStore();

        // 유효성 검사 ref
        const emplID = ref();
        const emplName = ref();
        const password = ref();
        
        // state
        const state = reactive({
            // 데이터 테이블 state
            dataTable: {

                // 게시판 목록
                userItems: null,

                // 검색 키워드 바인딩
                searchKeyword: null,

                // 계정 유형 초기화
                selectedUseType: 'SY',

                // 행 데이터 모델
                rowDataModel: new Object(),

                // 권한 그룹
                authorityGroup: null,

                // 수정된 권한 그룹 목록
                modifyItems : new Array(),

                // 행 개수
                rowCount: 0,

                // 아이디 중복여부
                isDuplicate: false,
            },

            dialog: {

                // 다이얼로그 헤더 텍스트
                header: "사용자 관리",

                // 다이얼로그 오픈여부
                isOpen: false,

                // 신규 여부
                isNew: false,
            },

            selectBox: {
                items: new Array(),
                useYN: [
                    {'CodeName':'Y', 'CodeValue':'Y'},
                    {'CodeName':'N', 'CodeValue':'N'}
                ]
            },
        });

        // 메서드
        const methods = {

            // 사용자 목록 조회
            getUserInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                userManage.getUserInfo(state.dataTable.selectedUseType, state.dataTable.searchKeyword).then(result => {

                    const userList = result.Table.rows;
                    const groupList = result.Table1.rows;

                    // 체크 박스 사용하기위해 데이터 설정
                    userList.forEach(row => { row.UseYN = row.UseYN === 'Y' ? true : false; });
                    groupList.forEach(row => { row.CheckYN = row.CheckYN === 'Y' ? true : false; });

                    state.dataTable.userItems = userList;
                    state.dataTable.authorityGroup = groupList;
                    state.dataTable.rowCount = userList.length;

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                    state.dataTable.isDuplicate = false;
                });
            },

            // 사용자 정보 저장
            saveUserInfo: () => {

                // TODO: 유효성 검사 로직 추가 필요 :이동엽

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                // 사용자 별 권한 그룹 설정
                let userByAuthorityGroup = new Array();
                
                state.dataTable.authorityGroup.forEach(row => {
                    
                    if(row.CheckYN === true) { userByAuthorityGroup.push(row) }
                });

                userManage.saveUserInfo(state.dataTable.rowDataModel, userByAuthorityGroup).then((response) => {

                    if(response.data.success) {

                        alertManager.alert.success('저장 되었습니다.')

                        // 목록 재조회
                        methods.getUserInfo();
                    }

                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;
                });
            },

            // 사용자 정보 삭제
            deleteUserInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                userManage.deleteUserInfo(state.dataTable.rowDataModel).then((response) => {

                    if(response.data.success) {
                        
                        alertManager.alert.success('삭제 되었습니다.')

                        // 목록 재조회
                        methods.getUserInfo();
                    }
                    
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;
                });
            },

            // 사용자 정보 저장 유효성검사
            userSaveValidation: () => {

                let validator = state.dataTable.rowDataModel

                // 사용자ID
                if (!validator.EmplID) {

                    emplID.value.focus()
                    alertManager.alert.warning('사용자ID를 입력해주세요')
                }
                // 중복체크
                else if (!validator.checkUserIDYn && !validator.updateSave) {

                    alertManager.alert.warning('사용자ID를 중복체크해 주세요.');
                }
                // 사용자명
                else if (!validator.EmplName) {

                    emplName.value.focus()
                    alertManager.alert.warning('사용자명을 입력해주세요')
                }
                // 비밀번호
                else if (!validator.Password) {

                    password.value.focus()
                    alertManager.alert.warning('비밀번호를 입력해주세요')
                }
                // 비밀번호 정규식 - 글자수 8글자 제한, 영어 숫자 특수문자 조합
                else if (!common.passwordRegularExpression(validator.Password)) {

                    password.value.focus()
                    return;
                }
                else {

                    // 사용자 상세 저장
                    if(state.dialog.isNew) alertManager.confirm.save().then(() => { methods.saveUserInfo() })
                    else alertManager.confirm.edit().then(() => { methods.saveUserInfo() })
                }
            },

            // 사용자 정보 수정 유효성검사
            userUpdateValidation: () => {

                let validator = state.dataTable.rowDataModel

                // 사용자명
                if (!validator.EmplName) {

                    emplName.value.focus()
                    alertManager.alert.warning('사용자명을 입력해주세요')
                }
                // 비밀번호
                else if (!validator.Password && state.dataTable.selectedUseType !== 'AD') {

                    password.value.focus()
                    alertManager.alert.warning('비밀번호를 입력해주세요')
                }
                // 비밀번호 정규식 - 글자수 8글자 제한, 영어 숫자 특수문자 조합
                else if (!!state.dataTable.rowDataModel.Password && !common.passwordRegularExpression(validator.Password)) {

                    password.value.focus()
                    return;
                }
                else {

                    // 사용자 상세 저장
                    if(state.dialog.isNew) alertManager.confirm.save().then(() => { methods.saveUserInfo() })
                    else alertManager.confirm.edit().then(() => { methods.saveUserInfo() })
                }
            },

            // 아이디 중복확인
            duplicateEmplID: () => {

                if (!state.dataTable.rowDataModel.EmplID) {

                    emplID.value.focus()
                    alertManager.alert.warning("아이디를 입력해주세요.")
                }
                else {

                    // 아이디 정규 체크
                    if (common.idRegularExpression(state.dataTable.rowDataModel.EmplID) === false) return

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);
    
                    userManage.getDuplicationUserInfo(state.dataTable.rowDataModel.EmplID).then(response => {
                        
                        if(response.data.success) {

                            let result = response.data.result.value;

                            if (result == 0) {

                                state.dataTable.rowDataModel.checkUserIDYn = true;
                                alertManager.alert.success('사용이 가능한 ID입니다.');
                            } else {

                                state.dataTable.rowDataModel.checkUserIDYn = false;
                                alertManager.alert.warning('사용이 불가능한 ID입니다.');
                            }
                        }
                    }).finally(() => {
    
                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    });
                }
            },
            // 아이디 변경 메서드
            changeEmplID: () => {

                state.dataTable.isDuplicate = false;
            },
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 데이터 테이블 관련 이벤트 핸들러
            dataTable: {
                
                // 행 클릭 이벤트 처리
                onRowClick: event => {

                    // 행 데이터 모델 설정
                    state.dataTable.rowDataModel = Object.assign({}, event.data);

                    //사용여부 true , false 에서 Y , N 으로 변경
                    state.dataTable.rowDataModel.UseYN = state.dataTable.rowDataModel.UseYN === true ? 'Y' : 'N';

                    // 사용자별 권한 그룹 목록
                    let userAuthGroupList = event.data.GrpID

                    // 그룹 목록 존재 시
                    if(!!userAuthGroupList) {
                        
                        // 직렬화된 목록을 배열에 담기
                        userAuthGroupList = event.data.GrpID.split(', ')

                        // 권한 그룹리스트에 체크 설정
                        userAuthGroupList.forEach(userGroup => {
                            
                            state.dataTable.authorityGroup.forEach(userGroupList => {
                                
                                // 선택된 행의 권한 그룹 속성 설정
                                if(userGroup.replace(' ','') === userGroupList.GrpID) {

                                    userGroupList.CheckYN = true
                                }
                            });
                        })
                    }

                    // 팝업 오픈
                    state.dialog.isOpen = true;

                    // 수정 체크
                    state.dataTable.rowDataModel.updateSave = true;
                },
            },

            // 다이얼로그 관련 이벤트 핸들러
            dialog: {

                // show 이벤트 처리
                show: () => {

                    //중복체크 여부 초기화
                    state.dataTable.rowDataModel.checkUserIDYn = false;

                    // 신규 다이얼로그 모드
                    if(state.dialog.isNew) {

                        // 신규 계정 유형 디폴트 값 설정
                        state.dataTable.rowDataModel.UseType = 'SY';

                        //신규 버튼 클릭시 드롭다운 리스트 디폴트값 설정
                        state.dataTable.rowDataModel.UseYN ='Y';

                        //수정여부
                        state.dataTable.rowDataModel.updateSave = false;
                    }
                },

                // hide 이벤트 처리
                hide: () => {

                    // 행 데이터모델 초기화
                    state.dataTable.rowDataModel = new Object();
                    state.dialog.isNew = false;
                    state.dialog.checkUserIDYn = false;
                    state.dataTable.rowDataModel.updateSave = false;

                    // 권한그룹 체크 초기화
                    state.dataTable.authorityGroup.forEach(row => { row.CheckYN = false });
                },
            },

            // 삭제 버튼 클릭 이벤트
            onDeleteButtonClick: () => {

                alertManager.confirm.remove().then(() => { methods.deleteUserInfo() })
            }
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 사용자 목록 초기조회
            methods.getUserInfo();

            // 계정 유형 초기조회
            common.getSystemCodeInfo('CODEINFO', 'CD025000').then(result => state.selectBox.items = result);
        });

        return {
            state,
            userManage,
            methods,
            eventHandler,
            emplID,
            emplName,
            password,
        }
    }
}
</script>